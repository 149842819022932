import React from 'react';
import { Route, Switch } from 'react-router';
import routes from '../common/routes';
import { ExpectedQuotesIndex } from './ExpectedQuotesIndex';

export const ExpectedQuotes = () => {
  return (
    <Switch>
      <Route path={routes.expectedQuotes} component={ExpectedQuotesIndex} />
    </Switch>
  );
};
