import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import { differenceInYears, parse } from 'date-fns';
import { TabForm } from '../../../../common/components/Tabs';
import { FormSection, FormSubmitButton } from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import {
  RadioInput,
  RadioGroup,
  CheckBoxInput,
  Label,
  TextInput,
} from '../../../../common/components/Form/FormInputs';
import { traverseObject } from '../../../../common/utils';
import { isQuoteVersionFilled } from './utils';
import {
  QuoteUploadFilename,
  QuoteUploadWrapper,
} from '../../../../common/components/QuoteUpload/QuoteUpload';
import { PrimaryButton, SecondaryButton } from '../../../../common/components/Buttons';

export const BlocTabForm = ({
  folder,
  pending,
  disabled,
  fieldPrefix,
  quoteVersions,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { values, handleSubmit, isSubmitting, setFieldValue } = useFormikContext();

  const formQuoteVersions = traverseObject(values, `${fieldPrefix}.quoteVersions`);

  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState(null);
  const [quoteStatus, setquoteStatus] = useState('');
  const [comment, setComment] = useState('');

  const currentQuote = useMemo(() => {
    const currentQuoteVersions = [...formQuoteVersions].reverse();
    return currentQuoteVersions.findIndex(quote => quote.quoteReceivedAt);
  }, [formQuoteVersions]);

  const isAddButtonDisabled =
    !isQuoteVersionFilled(quoteVersions[quoteVersions.length - 1]) ||
    formQuoteVersions.length > quoteVersions.length ||
    disabled;

  const openModal = index => {
    setSelectedQuoteIndex(index);
    setComment(quoteVersions[index]?.comment || '');
    setquoteStatus(quoteVersions[index]?.quoteStatus || '');
    onOpen();
  };

  const handleFinalize = () => {
    if (selectedQuoteIndex !== null) {
      setFieldValue(`${fieldPrefix}.quoteVersions.${selectedQuoteIndex}.quoteStatus`, quoteStatus);
      setFieldValue(`${fieldPrefix}.quoteVersions.${selectedQuoteIndex}.comment`, comment);
      if (quoteStatus === 'additional_request') {
        setFieldValue(
          `${fieldPrefix}.quoteVersions.${selectedQuoteIndex}.additionalRequestsSentAt`,
          new Date(),
        );
      }
    }

    // Submit the form
    handleSubmit();

    onClose();
  };

  const isFinalizeButtonEnabled = quoteStatus === 'valid' || comment.length >= 10;

  return (
    <TabForm mx="0" ml={5} maxWidth="none" onSubmit={handleSubmit} {...props}>
      <Stack spacing="3.5rem">
        <FormSection title="Reception des devis">
          <CheckBoxInput mb={5} id={`${fieldPrefix}.noFollowup`} name={`${fieldPrefix}.noFollowup`}>
            Proposition sans suite
          </CheckBoxInput>

          <FieldArray
            name={`${fieldPrefix}.quoteVersions`}
            render={arrayHelpers => (
              <>
                <Button
                  color="coral.900"
                  variantColor={null}
                  leftIcon="plus"
                  mb={6}
                  bg="select.bg.isSelected"
                  _hover={{ bg: 'select.bg.isSelected' }}
                  _focus={{ bg: 'select.bg.isSelected' }}
                  disabled={isAddButtonDisabled}
                  onClick={() =>
                    arrayHelpers.push({
                      quoteReceivedAt: null,
                      additionalRequestsSentAt: null,
                      quoteStatus: null,
                    })
                  }
                >
                  Ajouter un nouveau devis
                </Button>
                <Stack spacing={6} minW="100%">
                  {[...formQuoteVersions].reverse().map((quote, mapIndex) => {
                    const index = formQuoteVersions.length - mapIndex - 1;

                    const isCurrentQuote = currentQuote === mapIndex;
                    const isQuoteVersionDisabled = disabled;
                    const isQuoteVersionReadOnly = isQuoteVersionFilled(quoteVersions[index]);

                    return (
                      <Box
                        key={index}
                        bg="lightGray4"
                        rounded="15px"
                        p={6}
                        shadow={isCurrentQuote ? 'sm' : null}
                        border={isCurrentQuote ? '1px solid' : null}
                        borderColor="lightGray2"
                        opacity={isQuoteVersionReadOnly && !isCurrentQuote ? 0.8 : null}
                      >
                        <Text color="lightGray2" fontWeight="bold" mb={5}>
                          Devis n°{index + 1}
                        </Text>
                        <Box mb={6}>
                          <Label>Devis</Label>
                          <QuoteUploadWrapper bg="none" p={0}>
                            <QuoteUploadFilename
                              quoteVersion={quote}
                              placeholder="Aucun devis disponible"
                            />
                          </QuoteUploadWrapper>
                        </Box>
                        <TextInput
                          name={`${fieldPrefix}.quoteVersions.${index}.quoteStatus`}
                          display="none"
                        />
                        <TextInput
                          name={`${fieldPrefix}.quoteVersions.${index}.comment`}
                          display="none"
                        />
                        <DateInput
                          label="Date de réception du devis"
                          name={`${fieldPrefix}.quoteVersions.${index}.quoteReceivedAt`}
                          id={`${fieldPrefix}.quoteVersions.${index}.quoteReceivedAt`}
                          disabled={isQuoteVersionDisabled}
                          readOnly={
                            (quoteVersions[index]?.quoteReceivedAt || isQuoteVersionReadOnly) &&
                            !isQuoteVersionDisabled
                          }
                        />

                        <DateInput
                          label="Date de demandes complémentaires"
                          name={`${fieldPrefix}.quoteVersions.${index}.additionalRequestsSentAt`}
                          id={`${fieldPrefix}.quoteVersions.${index}.additionalRequestsSentAt`}
                          style={{ mb: 6 }}
                          readOnly
                        />
                        <PrimaryButton
                          display="block"
                          mx="auto"
                          disabled={
                            !quoteVersions[index]?.quoteReceivedAt ||
                            quoteVersions[index]?.quoteStatus
                          }
                          onClick={() => openModal(index)}
                        >
                          Analyser le devis
                        </PrimaryButton>
                      </Box>
                    );
                  })}
                </Stack>
              </>
            )}
          />
        </FormSection>
      </Stack>

      <FormSubmitButton
        type="submit"
        disabled={isSubmitting || pending}
        isLoading={isSubmitting}
        loadingText="Enregistrer"
      >
        Enregistrer
      </FormSubmitButton>
      <Modal isOpen={isOpen} closeOnOverlayClick={false} size="full">
        <ModalOverlay />
        <ModalContent rounded="lg" overflow="hidden" width="90%" height="90%" my="5vh">
          <>
            <ModalHeader as="div" bg="darkGray" color="white">
              <Heading as="h2" fontSize="xl" fontWeight="bold">
                <Text
                  as="span"
                  fontFamily="heading"
                  opacity="0.5"
                  textTransform="uppercase"
                  fontWeight="600"
                >
                  Dossier n°{' '}
                </Text>
                {folder?.formattedId}
                <Text as="span" d="inline-block" mx={2} opacity="0.65">
                  -
                </Text>{' '}
                <Text mt={2} as="small">
                  <Text as="span" fontWeight="semibold" textTransform="uppercase">
                    {folder?.recipient.lastName || '-'}
                  </Text>{' '}
                  {folder?.recipient.firstName || '-'}
                </Text>
              </Heading>
            </ModalHeader>
            <ModalCloseButton color="white" _focus={{ shadow: 'none' }} />
            <ModalBody p={5} display="grid" gridTemplateColumns="65% 35%" style={{ gap: '1rem' }}>
              <Box
                as="iframe"
                src={quoteVersions[selectedQuoteIndex]?.fileData}
                width="100%"
                height="100%"
                border="1px solid"
                borderColor="placeholder"
                rounded="lg"
              >
                <Text as="span" d="block" mt={5} textAlign="center">
                  Votre navigateur ne supporte pas l'affichage de fichiers PDF.
                </Text>
              </Box>
              <Box height="100%" display="flex" flexDir="column">
                <Textarea
                  height="100%"
                  onChange={e => setComment(e.target.value)}
                  placeholder="Indiquez ici vos remarques et demandes de modifications éventuelles. Elles seront communiquées telles quelles à l’entreprise."
                  borderColor="lightGray2"
                  disabled={quoteStatus !== 'additional_request'}
                  _focus={{ outline: 0, borderColor: 'coral.900' }}
                  _invalid={{ borderColor: 'errorBorderColor' }}
                  resize="vertical"
                />
                <Box mt={2}>
                  <RadioGroup
                    value={quoteStatus}
                    onChange={e => setquoteStatus(e.target.value)}
                    isInline
                    legend="Statut de l’analyse du devis"
                  >
                    <RadioInput value="valid">Totalement valide</RadioInput>
                    <RadioInput value="additional_request">Demandes complémentaires</RadioInput>
                  </RadioGroup>
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter pt={0}>
              <SecondaryButton type="button" mr={3} onClick={() => onClose()}>
                Annuler
              </SecondaryButton>
              <PrimaryButton
                type="button"
                onClick={() => handleFinalize()}
                disabled={!isFinalizeButtonEnabled}
              >
                Finaliser
              </PrimaryButton>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </TabForm>
  );
};
