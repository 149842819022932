import { expectedQuotesConstants } from './constants';

export const initialState = {
  data: [],
  pending: false,
};

export function expectedQuotes(state = initialState, action) {
  switch (action.type) {
    case expectedQuotesConstants.GET_EXPECTED_QUOTES_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case expectedQuotesConstants.GET_EXPECTED_QUOTES_SUCCESS:
      return {
        ...state,
        data: action.data.quotes,
        meta: action.data.meta,
        pending: false,
      };
    case expectedQuotesConstants.UPDATE_EXPECTED_QUOTES_COMMENTS:
      return {
        ...state,
        data: state.data.map(quote => {
          if (quote.id === action.quote) {
            return {
              ...quote,
              comments: action.folder.comments,
            };
          }
          return quote;
        }),
      };
    case expectedQuotesConstants.GET_EXPECTED_QUOTES_FAILURE:
    default:
      return state;
  }
}
