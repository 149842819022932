import { useState } from 'react';

export const useFoldersFilters = () => {
  const [currentTags, setCurrentTags] = useState([]);
  const [currentPhases, setCurrentPhases] = useState([]);
  const [currentStates, setCurrentStates] = useState(['Actif', 'En instance']);

  return [
    {
      currentTags,
      setCurrentTags,
      currentPhases,
      setCurrentPhases,
      currentStates,
      setCurrentStates,
    },
  ];
};
