import React from 'react';
import { Tag, Box } from '@chakra-ui/core';
import { TabPanel as BaseTabPanel } from '../common/components/Tabs';

export const QUOTES_TYPES = {
  green: 'Dossiers actifs',
  orange: 'En instance',
  red: 'Archive',
  gray: 'Tous',
};

export const TypeTag = ({ children, variantColor, ...props }) => (
  <Tag
    variantColor={null}
    variant="solid"
    bg={`tag.bg.${variantColor}`}
    color={`tag.color.${variantColor}`}
    rounded="0.5rem"
    fontWeight="600"
    {...props}
  >
    {children}
  </Tag>
);

export const TabPanel = ({ children, ...props }) => {
  return (
    <BaseTabPanel pos="relative" pb={0} {...props}>
      {children}
    </BaseTabPanel>
  );
};

export const TBody = ({ children, ...props }) => {
  return (
    <Box as="tbody" d="block" flexGrow="1" overflow="auto" marginTop={3} pos="relative" {...props}>
      {children}
    </Box>
  );
};
