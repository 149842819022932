import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack } from '@chakra-ui/core';
import { TextInput, RadioGroup, RadioInput, CheckboxGroupInput, PhoneInput } from '../Form/FormInputs';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Form, FormActions, FormSection } from '../Form/Form';
import { history } from '../../helpers/history';
import { SelectInput } from '../Form/FormSelectInput';
import Can from '../../../roleBasedAccess/Can';

export const CompanyForm = ({
  initialValues,
  onSubmit,
  pending,
  error,
  mode,
  departments,
  qualifications,
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  const filterDepartments = inputValue => {
    if (!inputValue) return [];
    return departments
      ?.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))
      .sort(
        (a, b) =>
          a.label.toLowerCase().indexOf(inputValue.toLowerCase()) -
          b.label.toLowerCase().indexOf(inputValue.toLowerCase()),
      )
      .slice(0, 20);
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterDepartments(inputValue));
    }, 1000);
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={mode === 'edit'}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setTouched }) => {
        setSubmitting(false);
        onSubmit(values);
        setTouched({});
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit}>
          <Stack spacing={16}>
            <FormSection title="Informations de contact">
              <TextInput
                label="Nom de l'entreprise"
                type="text"
                id="name"
                name="name"
                placeholder="Ma société"
                disabled={pending}
                requiredInput
              />
              <TextInput
                label="Mail"
                type="email"
                id="email"
                name="email"
                placeholder="contact.societe@gmail.com"
                disabled={pending}
                requiredInput
              />
              <PhoneInput
                label="Téléphone"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="0602050842"
                disabled={pending}
              />
              <SelectInput
                mt="2.8rem"
                placeholder="Sélectionner les départements"
                label="Départements"
                name="departments"
                id="departments"
                isMulti
                isAsync
                defaultOptions
                cacheOptions
                noOptionsMessage={({ inputValue }) => {
                  if (inputValue) return 'Aucun code postal ne correspond à cette recherche';
                  return 'Taper pour rechercher';
                }}
                loadingMessage={() => 'Recherche en cours...'}
                loadOptions={loadOptions}
                disabled={formik.isSubmitting}
              />
            </FormSection>
            <FormSection title="Certification">
              <RadioGroup
                flexDir="column"
                name="certification"
                legend="L'entreprise dispose-t-elle des certifications requises par Action Logement ?"
              >
                <RadioInput value="true" disabled={pending}>
                  Oui
                </RadioInput>

                <RadioInput value="false" disabled={pending}>
                  Non
                </RadioInput>
              </RadioGroup>
            </FormSection>
            <FormSection title="Autorisations">
              <RadioGroup name="hasAccessToProjectPlace" legend="Accès à la projectplace">
                <RadioInput value="true" disabled={pending}>
                  Oui
                </RadioInput>
                <RadioInput value="false" disabled={pending}>
                  Non
                </RadioInput>
              </RadioGroup>
              <RadioGroup
                name="hasNotifications"
                legend="Notifications mail"
                fieldsetStyle={{
                  mb: 0,
                }}
              >
                <RadioInput value="true" disabled={pending}>
                  Oui
                </RadioInput>
                <RadioInput value="false" disabled={pending}>
                  Non
                </RadioInput>
              </RadioGroup>
            </FormSection>

            <Can
              perform="companies:edit_qualifications"
              yes={() => (
                <FormSection title="Qualifications">
                  {formik.values && formik.values.qualificationIds
                    ? qualifications.map(qualification => {
                        const isChecked = formik.values.qualificationIds.includes(qualification.id);

                        return (
                          <CheckboxGroupInput
                            key={`qualification_${qualification.id}`}
                            name="qualificationIds"
                            value={qualification.id}
                            checked={isChecked}
                            onChange={e => {
                              // eslint-disable-next-line prefer-const
                              let { checked, value } = e.target;
                              const id = Number(value);
                              const currentIds = formik.values.qualificationIds;
                              const updatedQualifications = [...currentIds];

                              if (!updatedQualifications.includes(id)) {
                                updatedQualifications.push(id);
                              } else {
                                checked = false;
                                const index = updatedQualifications.indexOf(id);
                                if (index > -1) {
                                  updatedQualifications.splice(index, 1);
                                }
                              }

                              formik.setFieldValue('qualificationIds', updatedQualifications);
                            }}
                            isDisabled={pending}
                          >
                            {qualification.label}
                          </CheckboxGroupInput>
                        );
                      })
                    : qualifications.map(qualification => {
                        return (
                          <CheckboxGroupInput
                            key={`qualification_${qualification.id}`}
                            name="qualificationIds"
                            value={qualification.id}
                            checked={false}
                            onChange={e => {
                              // eslint-disable-next-line prefer-const
                              let { checked, value } = e.target;
                              const id = Number(value);

                              const currentIds = Array.isArray(formik.values.qualificationIds)
                                ? formik.values.qualificationIds
                                : [];
                              const updatedQualifications = [...currentIds];

                              if (!updatedQualifications.includes(id)) {
                                updatedQualifications.push(id);
                              } else {
                                checked = false;
                                const index = updatedQualifications.indexOf(id);
                                if (index > -1) {
                                  updatedQualifications.splice(index, 1);
                                }
                              }

                              formik.setFieldValue('qualificationIds', updatedQualifications);
                            }}
                            isDisabled={pending}
                          >
                            {qualification.label}
                          </CheckboxGroupInput>
                        );
                      })}
                </FormSection>
              )}
            />
          </Stack>

          <FormActions mt={20}>
            <SecondaryButton type="button" onClick={() => history.goBack()}>
              Annuler
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={pending}>
              Enregistrer
            </PrimaryButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};
