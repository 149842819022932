import React, { useRef, useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import {
  Box,
  Button,
  FormControl,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import {
  ErrorMessage, PhoneInput,
  RadioGroup,
  RadioInput,
  TextInput,
} from '../../../../common/components/Form/FormInputs';
import { SelectInput } from '../../../../common/components/Form/FormSelectInput';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import {
  AutoChangeAddress,
  FormAddressGroup,
} from '../../../../common/components/Form/FormAddressGroup';
import { usersAPI } from '../../../../common/api/users';
import { foldersAPI } from '../../../../common/api/folders';
import { PrimaryButton, SecondaryButton } from '../../../../common/components/Buttons';

export const Bloc0Tab1 = ({
  bloc0: { error, pending, ...initialValues },
  operators,
  onSubmit,
  user,
}) => {
  const formikRef = useRef();
  const [availableTags, setAvailableTags] = useState([]);
  const [availableExterns, setAvailableExterns] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (formikRef.current) {
      if (error) {
        Object.entries(error.errors).forEach(([key, values]) => {
          if (key.match(/^folderN/)) {
            let newKey = `${key}`.replace('folderN', 'n');
            newKey = newKey.replace(/\](.)/, (match, p1) => `].${p1.toLowerCase()}`);
            formikRef.current.setFieldError(newKey, values);
          } else {
            formikRef.current.setFieldError(key, values);
          }
        });
      }
    }
  }, [error]);

  const onDeleteNotificationRecipient = (formik, index) => {
    const notificationRecipientToDelete = formik.values.notificationRecipients[index];
    let updatedQuoteGroups;

    if (notificationRecipientToDelete.id) {
      updatedQuoteGroups = formik.values.notificationRecipients;
      updatedQuoteGroups[index] = {
        ...notificationRecipientToDelete,
        _destroy: true,
      };
    } else {
      formik.values.notificationRecipients.splice(index, 1);
      updatedQuoteGroups = formik.values.notificationRecipients;
    }

    formik.setFieldValue('quoteGroups', updatedQuoteGroups);
  };

  const onAddNotificationRecipient = formik => {
    const newNotificationRecipient = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      relationshipWithRecipient: '',
    };

    const updatedNotificationRecipients = [
      ...formik.values.notificationRecipients,
      newNotificationRecipient,
    ];
    formik.setFieldValue('notificationRecipients', updatedNotificationRecipients);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit({ ...initialValues, ...values }).then(() => {
          setSubmitting(false);
          setTouched({});
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="3.5rem">
            <FormSection title="Contact initial">
              <RadioGroup isInline name="requestSource" legend="La demande vient d’un...">
                <RadioInput value="particulier">Particulier</RadioInput>
                <RadioInput value="professionel">Professionnel</RadioInput>
              </RadioGroup>

              {user?.role?.match(/(référent externe|pilote externe)/) ? null : (
                <UpdateTagsAndExternOperatorsList
                  setAvailableTags={setAvailableTags}
                  setAvailableExterns={setAvailableExterns}
                />
              )}

              <SelectInput
                label="Information complémentaire"
                name="tag"
                id="tag"
                isClearable
                isCreatable
                isUpperCase
                css={{
                  '[class*="-container"]': { width: '145px' },
                  '[class*="-placeholder"]': { textTransform: 'none' },
                }}
                options={availableTags}
                noOptionsMessage={() => 'Tapez pour créer un tag'}
                placeholder="tag"
              />
              <FormControl>
                <ErrorMessage
                  d={formik.errors?.folderTag && !formik.touched?.tag ? 'block' : 'none'}
                  mt={formik.errors?.folderTag && !formik.touched?.tag ? -5 : 0}
                  mb={formik.errors?.folderTag && !formik.touched?.tag ? 4 : '1.75rem'}
                >
                  {formik.errors?.folderTag}
                </ErrorMessage>
              </FormControl>

              <SelectInput
                label="Référent ou pilote externe"
                name="referentOrPiloteExtUserId"
                id="referentOrPiloteExtUserId"
                noOptionsMessage={() => 'Aucun externe trouvé avec le tag choisi'}
                options={availableExterns}
                placeholder="Sélectionner un opérateur"
              />

              <DateInput
                label="Date de contact initial"
                name="contactedAt"
                id="contactedAt"
                enabledFutureDates
              />
            </FormSection>

            <FormSection title="Entretien social initial">
              <SelectInput
                label="Conseiller social"
                name="conseillerSocialUserId"
                id="conseillerSocialUserId"
                isDisabled={!formik.values.tag}
                options={operators?.map(operator => ({
                  label: operator.fullName,
                  value: operator.id,
                }))}
                noOptionsMessage={() => 'Aucun conseiller social disponible'}
                placeholder="Sélectionner un opérateur"
              />

              <DateInput
                label="Date de l'entretien"
                name="appointedAt"
                id="appointedAt"
                enabledFutureDates
                disabled={!formik.values.tag}
              />
            </FormSection>

            <FormSection title="Logement à adapter">
              <RadioGroup
                isInline
                name="doesRecipientOwnTheHousing"
                legend="Le logement est-il occupé par le bénéficiaire ?"
              >
                <RadioInput value="true">Oui</RadioInput>
                <RadioInput value="false">Non</RadioInput>
              </RadioGroup>

              {formik.values.doesRecipientOwnTheHousing !== undefined && <AutoChangeAddress />}

              {formik.values.doesRecipientOwnTheHousing && (
                <FormAddressGroup
                  addressName="housingStreetAddress"
                  postalCodeName="housingPostalCode"
                  cityName="housingCity"
                />
              )}
            </FormSection>

            <FormSection
              title="Périmètre du diagnostic socio-administratif"
              id="isFundingsSearchUseful"
            >
              <RadioGroup
                isInline
                name="isFundingsSearchUseful"
                legend="Une recherche de financements est-elle utile ?"
              >
                <RadioInput value="true">Oui</RadioInput>
                <RadioInput value="false">Non</RadioInput>
              </RadioGroup>
              <RadioGroup
                isInline
                name="isDematerializedFolder"
                legend="Le bénéficiaire souhaite-t-il un dossier dématérialisé ?"
              >
                <RadioInput value="true">Oui</RadioInput>
                <RadioInput value="false">Non</RadioInput>
              </RadioGroup>
              {initialValues.folder && (
                <UpdateNotificationRecipients
                  recipient={initialValues.folder.recipient}
                  recipientContact={initialValues.folder.contact}
                />
              )}
              {formik.values.isDematerializedFolder === 'true' ? (
                <Stack spacing={4}>
                  <Text color="semiDarkGray.600" fontWeight={600} mt={6}>
                    Veuillez vérifier les adresses mails suivantes
                  </Text>
                  {formik.values.notificationRecipients.map((recipient, index) => (
                    <Box
                      px={8}
                      pt={6}
                      pb={2}
                      bg="lightGray"
                      rounded="lg"
                      // eslint-disable-next-line no-underscore-dangle
                      d={recipient._destroy ? 'none' : null}
                    >
                      <Text
                        mb={6}
                        fontSize="lg"
                        fontWeight={700}
                        color="gray"
                        d="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        Destinataire {index + 1}
                        {index === 0 ? null : (
                          <Button
                            variantColor={null}
                            p={1}
                            size={0}
                            color="gray"
                            _hover={{ color: 'red' }}
                            aria-label={`Supprimer le destinataire n°${index}`}
                            onClick={onOpen}
                          >
                            <Icon name="trash" />
                          </Button>
                        )}
                      </Text>
                      <TextInput
                        label="Prénom"
                        name={`notificationRecipients[${index}].firstName`}
                        placeholder="Jean"
                        requiredInput
                      />
                      <TextInput
                        label="Nom"
                        name={`notificationRecipients[${index}].lastName`}
                        placeholder="Dupont"
                        requiredInput
                      />
                      <PhoneInput
                        label="Téléphone portable"
                        type="tel"
                        name={`notificationRecipients[${index}].phone`}
                        placeholder="+330XXXXXXXXX"
                      />
                      <TextInput
                        label="Email"
                        type="email"
                        name={`notificationRecipients[${index}].email`}
                        placeholder="beneficiaire@mail.fr"
                        requiredInput
                      />
                      <TextInput
                        label="Lien de parenté bénéficiaire"
                        name={`notificationRecipients[${index}].relationshipWithRecipient`}
                        placeholder="Enfant"
                        requiredInput
                      />
                      <ConfirmationModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onSubmit={() => onDeleteNotificationRecipient(formik, index)}
                        pending={pending || formik.isSubmitting}
                      />
                    </Box>
                  ))}
                  <Button
                    color="coral.900"
                    variantColor={null}
                    rightIcon="plus"
                    bg="select.bg.isSelected"
                    _hover={{ bg: 'select.bg.isSelected' }}
                    _focus={{ bg: 'select.bg.isSelected' }}
                    onClick={() => onAddNotificationRecipient(formik)}
                  >
                    Ajouter un destinataire
                  </Button>
                </Stack>
              ) : null}
            </FormSection>
            <FormCTAButton
              mb={0}
              mx="auto"
              disabled={
                !formik.values.tag ||
                !formik.values.isFundingsSearchUseful ||
                !formik.values.housingCity ||
                !formik.values.appointedAt ||
                !formik.values.housingPostalCode ||
                !formik.values.housingStreetAddress ||
                !formik.values.doesRecipientOwnTheHousing ||
                !formik.values.isDematerializedFolder ||
                formik.values.notificationRecipients?.find(
                  contact =>
                    !contact.relationshipWithRecipient ||
                    !contact.firstName ||
                    !contact.lastName ||
                    !contact.email,
                )
              }
              onClick={() => formik.submitForm().catch(console.error)}
            >
              Accéder au Diagnostic socio-administratif
            </FormCTAButton>
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};

const UpdateTagsAndExternOperatorsList = ({ setAvailableTags, setAvailableExterns }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (values.referentOrPiloteExtUserId?.value) {
      usersAPI
        .getUser(values.referentOrPiloteExtUserId?.value)
        .then(user => {
          setAvailableTags(user.tags.map(value => ({ label: value, value })));
        })
        .catch(console.log);
    } else {
      foldersAPI
        .getTags({ search: '' })
        .then(tags => {
          setAvailableTags(tags.map(value => ({ label: value, value })));
        })
        .catch(console.log);
    }
  }, [setAvailableTags, values.folder, values.referentOrPiloteExtUserId]);

  useEffect(() => {
    const byTags = values.tag ? [values.tag.value] : null;
    usersAPI
      .getUsers(0, '', { perPage: 1000, byRoles: ['pilote externe', 'référent externe'], byTags })
      .then(({ users: operators }) => {
        setAvailableExterns(
          operators.map(operator => ({
            label: operator.fullName,
            value: operator.id,
            role: operator.role,
          })),
        );
      })
      .catch(console.log);
  }, [setAvailableExterns, values.folder, values.tag]);

  return null;
};

const UpdateNotificationRecipients = ({ recipient, recipientContact }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.isDematerializedFolder === 'true' && values.notificationRecipients.length === 0) {
      const contacts = [
        {
          firstName: recipient.firstName,
          lastName: recipient.lastName,
          email: recipient.email,
          phone: recipient.phoneNumber,
          relationshipWithRecipient: 'Le bénéficiaire',
        },
      ];

      if (recipientContact.firstName)
        contacts.push({
          firstName: recipientContact.firstName,
          lastName: recipientContact.lastName,
          email: recipientContact.email,
          phone: recipientContact.phoneNumber,
          relationshipWithRecipient: '',
        });

      setFieldValue('notificationRecipients', contacts);
    }
  }, [values]);

  return null;
};

const ConfirmationModal = ({ isOpen, onSubmit, pending, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} />
        <ModalBody>
          <p>Voulez-vous supprimer ce destinataire ?</p>
        </ModalBody>

        <ModalFooter>
          <SecondaryButton mr={3} onClick={onClose}>
            Non
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              onSubmit();
              onClose();
            }}
            isLoading={pending}
            loadingText="Publication en cours..."
          >
            Oui
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
