import { NavLink } from 'react-router-dom';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Box, Icon, Text, Stack, PseudoBox } from '@chakra-ui/core';
import Can from '../../../roleBasedAccess/Can';
import routes from '../../routes';

const MainNav = () => {
  return (
    <PseudoBox
      as="nav"
      bg="semiDarkGray.900"
      color="white"
      height="100%"
      px={4}
      pt={6}
      transition="0.2s ease"
      width="sidebar"
      position="absolute"
      left={0}
      top={0}
      zIndex="sticky"
      _hover={{
        width: '257px',
        svg: {
          marginRight: 4,
        },
        span: {
          width: 'auto',
          opacity: '1',
          transition: '0.6s ease',
        },
        ul: {
          alignItems: 'stretch',
        },
      }}
    >
      <Stack as="ul" listStyleType="none" alignItems="start">
        <Can
          perform="dashboard:visit"
          yes={() => (
            <MainNavLink to={routes.dashboard} icon="dashboard">
              Dashboard
            </MainNavLink>
          )}
        />
        <Can
          perform="report:read"
          yes={() => (
            <MainNavLink to={routes.reporting} icon="report-file">
              Reporting
            </MainNavLink>
          )}
        />
        <Can
          perform="beneficiaries:read"
          yes={() => (
            <MainNavLink to={routes.recipients} icon="beneficiaries">
              Bénéficiaires
            </MainNavLink>
          )}
        />
        <Can
          perform="pre-folder:edit"
          yes={() => (
            <MainNavLink to={routes.preFolders} icon="pre-folder">
              Pré-dossiers
            </MainNavLink>
          )}
        />
        <Can
          perform="folder:read"
          yes={() => (
            <MainNavLink to={routes.folders.index} icon="folder">
              Dossiers
            </MainNavLink>
          )}
          no={() => (
            <Can
              perform="folder:bloc:read"
              yes={() => (
                <MainNavLink to={routes.folders.index} icon="folder">
                  Dossiers
                </MainNavLink>
              )}
            />
          )}
        />
        <Can
          perform="project-place-work:read"
          yes={() => (
            <MainNavLink to={routes.projectPlace} icon="megaphone">
              Project place
            </MainNavLink>
          )}
          no={() => (
            <Can
              perform="project-place-ergo:read"
              yes={() => (
                <MainNavLink to={routes.projectPlace} icon="megaphone">
                  Project place
                </MainNavLink>
              )}
            />
          )}
        />
        <Can
          perform="expected-quotes:read"
          yes={() => (
            <MainNavLink to={routes.expectedQuotes} icon="expected-quotes">
              Devis attendus
            </MainNavLink>
          )}
        />
        <Can
          perform="quotes:read"
          yes={() => (
            <MainNavLink to={`${routes.quotes.index}${routes.quotes.types[0]}`} icon="quotes">
              Suivi des Devis
            </MainNavLink>
          )}
        />
        <Can
          perform="tasks:read"
          yes={() => (
            <MainNavLink to={routes.tasks.index} icon="tasks">
              Tâches
            </MainNavLink>
          )}
        />
        <Can
          perform="dashboard:visit"
          yes={() => (
            <MainNavLink to={routes.users} icon="users">
              Opérateurs
            </MainNavLink>
          )}
        />
        <Can
          perform="companies:read"
          yes={() => (
            <MainNavLink to={routes.companies} icon="companies">
              Entreprises
            </MainNavLink>
          )}
        />
        <Can
          perform="companies:read"
          yes={() => (
            <MainNavLink to={routes.ergoOffices} icon="offices">
              Cabinets
            </MainNavLink>
          )}
        />
        <Can
          perform="ergo-resources:read"
          yes={() => (
            <MainNavLink to={routes.ergoResources} icon="resource">
              Ressources
            </MainNavLink>
          )}
        />
      </Stack>
    </PseudoBox>
  );
};

const MainNavLink = ({ icon, to, children, exact }) => {
  return (
    <Box as="li" mb={5}>
      <NavLink
        to={to}
        exact={exact}
        css={theme =>
          css`
            display: block;
            &,
            svg {
              transition: 0.3s ease;
            }
            &.active {
              p {
                background-color: ${theme.colors.whiteAlpha[200]};
                font-weight: 600;
                font-size: ${theme.fontSizes.lg};
                padding: ${theme.space[4]};
                min-height: 62px;
                svg {
                  opacity: 1;
                  color: ${theme.colors.white};
                  width: 30px;
                  height: auto;
                  max-height: 30px;
                }
              }
            }
            &:hover {
              p {
                backgroundcolor: ${theme.colors.whiteAlpha[200]};
                color: ${theme.colors.white};
              }
              svg {
                opacity: 0.6;
              }
            }
          `
        }
      >
        <Box
          as="p"
          whiteSpace="nowrap"
          d="flex"
          alignItems="center"
          rounded="full"
          color="whiteAlpha.800"
          fontFamily="heading"
          px="1.2rem"
          overflow="hidden"
        >
          <Icon name={icon} size={6} color="white" opacity="0.4" />
          <Text
            as="span"
            d="block"
            width="0"
            opacity="0"
            transition="opacity 0.1s ease, padding 0.6s"
          >
            {children}
          </Text>
        </Box>
      </NavLink>
    </Box>
  );
};

export default MainNav;
