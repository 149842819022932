import { useRef, useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  Stack,
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/core';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { TabForm } from '../../../../common/components/Tabs';
import { FormSection, FormSubmitButton } from '../../../../common/components/Form/Form';
import { PrimaryButton, SecondaryButton } from '../../../../common/components/Buttons';
import {
  RadioGroup,
  RadioInput,
  CheckboxGroup,
  CheckboxGroupInput,
  CheckBoxInput,
  Label,
  TextInput,
} from '../../../../common/components/Form/FormInputs';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import {
  QuoteUploadFilename,
  QuoteUploadWrapper,
} from '../../../../common/components/QuoteUpload/QuoteUpload';

const TAB_SECTIONS = [
  {
    title: 'Informations générales',
    fields: ['adaptableHousing', 'hasTechnicalSheet', 'affectedRooms', 'affectedHabits'],
  },
  {
    title: 'Préconisations',
    fields: [
      'works',
      'isTechnicalAssistanceRequired',
      'isWheelchairOrOtherEquipmentsRequired',
      'isSpecificVehicleArrangementsRequired',
      'hasLifestyleAdviceWithEquipmentIntegration',
      'humanHelpOrService',
    ],
  },
  {
    title: 'Prise en compte de la LPPR',
    fields: ['hasLpprTechnicalRecommandation'],
  },
];

export const BlocATab2 = ({
  blocA: { error, pending, diagnosticSummary, ...initialValues },
  onSubmit,
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCancel = () => {
    onClose();
    formikRef.current.values.worksQuoteStatus = initialValues.worksQuoteStatus;
    formikRef.current.values.worksQuoteComment = '';
  }

  const [comment, setComment] = useState('');

  const onFinalize = () => {
    if(formikRef.current.values.worksQuoteStatus === 'valid') {
      formikRef.current.values.worksQuoteAdditionalRequestsSentAt = null;
    }
    else {
      formikRef.current.values.worksQuoteAdditionalRequestsSentAt = new Date()
      formikRef.current.values.worksQuoteComment = comment;
    }
    onClose();
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit({ ...initialValues, diagnosticSummary, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          {diagnosticSummary && (
            <Stack
              spacing="3.5rem"
              css={{
                '[role=group], [role=radiogroup]': {
                  paddingLeft: '1.5rem',
                },
                'fieldset:not(:first-of-type)': {
                  marginTop: '2.5rem',
                },
              }}
            >
              {TAB_SECTIONS.map(section => (
                <FormSection key={section.title} title={section.title}>
                  {section.fields.map(fieldName => (
                    <DiagnosticSummaryInput
                      key={fieldName}
                      name={fieldName}
                      content={diagnosticSummary[fieldName]}
                    />
                  ))}
                </FormSection>
              ))}
            </Stack>
          )}

          <FormSection title="Réception des devis de travaux" mt="2.5rem">
            <RadioGroup
              name="worksQuoteReceived"
              legend="Un devis travaux a-t-il été reçu ?"
              disabled={
                (!initialValues.typeOfVisit?.match(/(joint|existing_quote)/) ||
                  !initialValues.visitReminderSentAt) &&
                !initialValues.firstQuoteVersion?.downloadLink
              }
            >
              <RadioInput value="true" disabled={pending}>
                Oui
              </RadioInput>
              {formik.values?.worksQuoteReceived === 'true' ? (
                <Box py={4} ml="1.75rem" pl="1.75rem">
                  {formik.values.typeOfVisit?.match(/(joint|existing_quote)/) &&
                  formik.values.positionedCompany ? (
                    <Box mb={6}>
                      <Label>Devis</Label>
                      <QuoteUploadWrapper>
                        <QuoteUploadFilename
                          quoteVersion={initialValues.firstQuoteVersion}
                          placeholder="Aucun devis disponible"
                        />
                      </QuoteUploadWrapper>
                    </Box>
                  ) : null}
                  <DateInput
                    label="1ère date de reception"
                    name="worksQuoteReceivedAt"
                    id="worksQuoteReceivedAt"
                    disabled={!initialValues.typeOfVisit.match(/(joint|existing_quote)/)}
                  />

                  <TextInput name="worksQuoteComment" display="none" />

                  <DateInput
                    label="Date de demandes complémentaires"
                    name="worksQuoteAdditionalRequestsSentAt"
                    id="worksQuoteAdditionalRequestsSentAt"
                    style={{ mb: 6 }}
                    readOnly
                  />
                  <PrimaryButton 
                    display="block" 
                    mx="auto" 
                    onClick={() => onOpen()}
                    disabled={
                      formik.values?.worksQuoteStatus ||
                      !formik.values?.worksQuoteReceivedAt ||
                      !initialValues.typeOfVisit.match(/(joint|existing_quote)/)
                    }
                  >
                    Analyser le devis
                  </PrimaryButton>
                </Box>
              ) : null}
              <RadioInput value="false" disabled={pending}>
                Non
              </RadioInput>
            </RadioGroup>
          </FormSection>

          <FormSubmitButton
            type="submit"
            disabled={pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
          <Modal isOpen={isOpen} closeOnOverlayClick={false} size="full">
            <ModalOverlay />
            <ModalContent rounded="lg" overflow="hidden" width="90%" height="90%" my="5vh">
              <ModalHeader as="div" bg="darkGray" color="white">
                <Heading as="h2" fontSize="xl" fontWeight="bold">
                  <Text
                    as="span"
                    fontFamily="heading"
                    opacity="0.5"
                    textTransform="uppercase"
                    fontWeight="600"
                  >
                    Dossier n°{' '}
                  </Text>
                  {initialValues?.folder?.formattedId}
                  <Text as="span" d="inline-block" mx={2} opacity="0.65">
                    -
                  </Text>{' '}
                  <Text mt={2} as="small">
                    <Text as="span" fontWeight="semibold" textTransform="uppercase">
                      {initialValues?.folder?.recipient.lastName || '-'}
                    </Text>{' '}
                    {initialValues?.folder?.recipient.firstName || '-'}
                  </Text>
                </Heading>
              </ModalHeader>
              <ModalCloseButton color="white" _focus={{ shadow: 'none' }} />
              <ModalBody p={5} display="grid" gridTemplateColumns="65% 35%" style={{ gap: '1rem' }}>
                <Box
                  as="iframe"
                  src={initialValues?.firstQuoteVersion?.fileData}
                  width="100%"
                  height="100%"
                  border="1px solid"
                  borderColor="placeholder"
                  rounded="lg"
                >
                  <Text as="span" d="block" mt={5} textAlign="center">
                    Votre navigateur ne supporte pas l'affichage de fichiers PDF.
                  </Text>
                </Box>
                <Box height="100%" display="flex" flexDir="column">
                  <Textarea
                    height="100%"
                    onChange={e => setComment(e.target.value)}
                    placeholder="Indiquez ici vos remarques et demandes de modifications éventuelles. Elles seront communiquées telles quelles à l’entreprise."
                    borderColor="lightGray2"
                    disabled={formik.values?.worksQuoteStatus !== 'additional_request'}
                    _focus={{ outline: 0, borderColor: 'coral.900' }}
                    _invalid={{ borderColor: 'errorBorderColor' }}
                    resize="vertical"
                  />
                  <Box mt={2}>
                    <RadioGroup
                      name="worksQuoteStatus"
                      isInline
                      legend="Statut de l’analyse du devis"
                    >
                      <RadioInput value="valid">Totalement valide</RadioInput>
                      <RadioInput value="additional_request">Demandes complémentaires</RadioInput>
                    </RadioGroup>
                  </Box>
                </Box>
              </ModalBody>
              <ModalFooter pt={0}>
                <SecondaryButton type="button" mr={3} onClick={() => onCancel()}>
                  Annuler
                </SecondaryButton>
                <PrimaryButton 
                  type="button"
                  disabled={
                    !(formik.values?.worksQuoteStatus==='valid' || comment.length >= 10)
                  }
                  onClick={() => onFinalize()}
                >
                  Finaliser
                </PrimaryButton>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </TabForm>
      )}
    </Formik>
  );
};

const DiagnosticSummaryInput = ({ name, content }) => {
  switch (content.type) {
    case 'simple_choice_radio':
      return (
        <RadioGroup isInline name={name} legend={content.title}>
          {Object.entries(content.availableAnswers).map(([value, label]) => (
            <RadioInput key={label} value={value}>
              {label}
            </RadioInput>
          ))}
        </RadioGroup>
      );
    case 'multiple_choice':
      return (
        <CheckboxGroup name={name} legend={content.title}>
          {Object.entries(content.availableAnswers).map(([value, label]) => (
            <CheckboxGroupInput key={value} value={value.toUpperCase()}>
              {label}
            </CheckboxGroupInput>
          ))}
        </CheckboxGroup>
      );
    case 'simple_choice_checkbox':
      return (
        <Box
          mb={5}
          mt={name === 'isTechnicalAssistanceRequired' ? 8 : 0}
          css={{ span: { fontWeight: 600 } }}
        >
          <CheckBoxInput name={name}>{content.title}</CheckBoxInput>
        </Box>
      );
    default:
      return null;
  }
};
