import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Flex, Tabs, TabPanels, TabPanel } from '@chakra-ui/core';

import { QUOTE_GROUP_TYPES } from '../../../../redux/blocs/blocE/actions';
import { BlocTabForm } from './BlocTabForm';
import { SecondaryTab, SecondaryTabList } from '../../../../common/components/Tabs';
import { getFolderPath } from '../../../../common/routes';
import { history } from '../../../../common/helpers/history';
import { isCompanyPending, isCompanyValid } from './utils';

export const BlocFTab = ({
  match: {
    params: { folderId, tabIndex, companyIndex },
  },
  blocId,
  blocF: { error, pending, folder, ...initialValues },
  quoteGroup,
  quoteGroupIndex,
  onSubmit,
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit(values).then(() => {
          setSubmitting(false);
          setTouched({});
        });
      }}
    >
      {formik =>
        formik.values ? (
          <Tabs
            orientation="vertical"
            defaultIndex={0}
            index={Number(companyIndex) - 1}
            onChange={index =>
              history.push(getFolderPath(blocId, folderId, `${tabIndex}/${index + 1}`))
            }
          >
            <Flex alignItems="start" position="relative">
              <SecondaryTabList
                title={QUOTE_GROUP_TYPES[quoteGroup?.name]?.label}
                subtitle="(Entreprises)"
              >
                {quoteGroup?.companies.map((company, compIndex) => {
                  const formCompany =
                    formik.values.quoteGroups[quoteGroupIndex].companies[compIndex];
                  const isValid = isCompanyValid(formCompany);
                  const isPending = !isValid && isCompanyPending(formCompany);

                  return (
                    <SecondaryTab
                      key={company.id}
                      isValid={isValid}
                      isPending={isPending}
                      isCanceled={formCompany.noFollowup}
                    >
                      {company.name}
                    </SecondaryTab>
                  );
                })}
              </SecondaryTabList>

              <TabPanels>
                {quoteGroup?.companies.map((company, compIndex) => (
                  <TabPanel key={company.id}>
                    <BlocTabForm
                      pending={pending}
                      disabled={
                        formik.values.quoteGroups[quoteGroupIndex].companies[compIndex].noFollowup
                      }
                      fieldPrefix={`quoteGroups.${quoteGroupIndex}.companies.${compIndex}`}
                      quoteVersions={company?.quoteVersions}
                      folder={folder}
                    />
                  </TabPanel>
                ))}
              </TabPanels>
            </Flex>
          </Tabs>
        ) : null
      }
    </Formik>
  );
};
