import { css } from '@emotion/core';

export const globalStyle = theme => css`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;600;700&display=swap');

  :root {
    --PhoneInputCountryFlag-height: 1.5em !important;
  }

  html {
    font-family: ${theme.fonts.body};
    line-height: ${theme.lineHeights.shorter};
  }

  input[type='search']::-webkit-search-cancel-button {
    filter: brightness(0) saturate(100%) invert(61%) sepia(32%) saturate(5147%) hue-rotate(320deg)
      brightness(96%) contrast(104%);
  }

  .PhoneInput {
    gap: 0.5rem;
  }

  .PhoneInputInput {
    appearance: none;
    font-size: 16px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    height: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #b7b5b3;
    background-color: #fff;
    color: #363d44;
    font-weight: 600;
  }

  .PhoneInputInput:focus {
    border-color: #fc9895 !important;
  }

  .PhoneInputInput:disabled {
    cursor: not-allowed;
    background-color: rgba(196, 196, 196, 0.8);
    border-color: #b7b5b3;
    color: #363d44;
    opacity: 0.3;
  }

  .PhoneInputCountryIconImg,
  .PhoneInputCountryIcon {
    border-radius: 0.25rem;
  }
`;
