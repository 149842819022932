import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { CompanyForm } from '../common/components/CompanyForm/CompanyForm';
import { companiesActions } from '../redux/companies/actions';
import Can from '../roleBasedAccess/Can';
import { PrimaryButton } from '../common/components/Buttons';

const CompanyEdit = ({
  match: {
    params: { companyId },
  },
  error,
  pending,
  deletePending,
  company,
  getCompany,
  updateCompany,
  deleteCompany,
  departments,
  qualifications,
}) => {
  useEffect(() => {
    getCompany(companyId);
  }, [companyId, getCompany]);

  const onSubmit = newCompanyValues => {
    updateCompany(companyId, newCompanyValues);
  };

  const onDeleteCompany = () => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      `Voulez-vous supprimer l'entreprise n°${company.id} - ${company.name} ?`,
    );
    if (confirm) deleteCompany(companyId);
  };

  return (
    <PageDefault
      title="Edition de la fiche entreprise"
      pageAction={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Can
          perform="delete"
          yes={() => (
            <PrimaryButton
              isLoading={deletePending}
              loadingText="En supression..."
              onClick={onDeleteCompany}
            >
              Supprimer
            </PrimaryButton>
          )}
        />
      }
    >
      <CompanyForm
        qualifications={qualifications}
        departments={departments}
        initialValues={company}
        pending={pending}
        onSubmit={onSubmit}
        error={error}
        mode="edit"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ companies: state }) => ({
  company: state.company,
  error: state.error,
  pending: state.pending,
  deletePending: state.deletePending,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCompany: companiesActions.updateCompany,
      getCompany: companiesActions.getCompany,
      deleteCompany: companiesActions.deleteCompany,
    },
    dispatch,
  );

CompanyEdit.propTypes = {
  pending: PropTypes.bool,
  deletePending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  updateCompany: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
};

CompanyEdit.defaultProps = {
  pending: false,
  deletePending: false,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEdit);
