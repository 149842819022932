import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import RawPhoneInput from 'react-phone-number-input'
import {
  Text,
  Box,
  Input,
  Icon,
  Button,
  FormControl,
  FormLabel,
  ControlBox,
  VisuallyHidden,
  RadioGroup as RGroup,
  CheckboxGroup as CGroup,
  useTheme,
  Switch,
} from '@chakra-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { lightenDarkenColor } from '../../utils';
import { useFormSubmitDisabled } from './FormSubmitDisabled.context';
import 'react-phone-number-input/style.css'

export const TextInput = ({ label, requiredInput, readOnly, ...props }) => {
  const { id } = props;
  const [field, meta] = useField(props);

  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  return (
    <FormControl mb={6}>
      {label && (
        <Label htmlFor={id || props.name}>
          {label}
          {requiredInput && ' *'}
        </Label>
      )}
      <BaseInput
        {...field}
        isInvalid={meta.error && !meta.touched}
        value={field.value || field.value === 0 ? field.value : ''}
        readOnly={isFormSubmitDisabled || readOnly}
        {...props}
      />
      {!meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </FormControl>
  );
};

export const PhoneInput = ({ label, requiredInput, readOnly, ...props }) => {
  const { id } = props;
  const [field, meta] = useField(props);
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  return (
    <FormControl mb={4}>
      <Label htmlFor={id}>
        {label}
        {requiredInput && ' *'}
      </Label>
      <RawPhoneInput
        flagUrl="https://flagcdn.com/{xx}.svg"
        defaultCountry="FR"
        disabled={isFormSubmitDisabled || readOnly}
        id={props.id}
        name={props.name}
        initialValueFormat="national"
        inputComponent={BaseInput}
        placeholder={props.placeholder}
        value={field.value}
        onChange={value => {
          field.onChange({ target: { name: props.name, value } });
        }}
      />
      {!meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </FormControl>
  )
}

export const PasswordInput = ({ label, requiredInput, ...props }) => {
  const { id } = props;
  const [show, setShow] = useState(false);
  const [field, meta] = useField(props);

  return (
    <FormControl mb={4}>
      <Label htmlFor={id}>
        {label}
        {requiredInput && ' *'}
      </Label>
      <Box position="relative">
        <BaseInput
          type={show ? 'text' : 'password'}
          placeholder="*******"
          {...field}
          isInvalid={meta.error && !meta.touched}
          value={field.value || ''}
          {...props}
        />
        <Button
          onClick={() => setShow(!show)}
          position="absolute"
          variantColor={null}
          left="102%"
          top="50%"
          transform="translateY(-50%)"
          p={1}
          size={2}
        >
          <Icon name={show ? 'eye-opened' : 'eye-closed'} cursor="pointer" />
        </Button>
      </Box>
      {!meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </FormControl>
  );
};

export const RadioGroup = ({
  children,
  legend,
  requiredInput,
  disabled,
  readOnly,
  fieldsetStyle = {},
  legendStyle = {},
  ...props
}) => {
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  const [field, meta] = useField({ ...props });
  const isInvalid = meta.error && !meta.touched;
  const childrenWithInvalid = React.Children.map(children, child => {
    return (
      child &&
      React.cloneElement(child, {
        isInvalid,
        disabled: child.props.disabled || disabled,
        readOnly: child.props.readOnly || isFormSubmitDisabled || readOnly,
      })
    );
  });

  return (
    <Box as="fieldset" mb={4} {...fieldsetStyle}>
      {legend && (
        <Label as="legend" {...(props.isInline ? {} : { mb: 3 })} {...legendStyle}>
          {legend}
          {requiredInput && ' *'}
        </Label>
      )}
      <RGroup
        {...field}
        isInvalid={isInvalid}
        css={css`
          & > div {
            min-width: 37%;
          }
        `}
        value={field.value || ''}
        id={props.id || props.name}
        {...props}
      >
        {childrenWithInvalid}
      </RGroup>
      {!meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Box>
  );
};

export const RadioInput = ({ children, isInvalid, isChecked, ...props }) => {
  const theme = useTheme();

  return (
    <Label
      color="semiDarkGray.900"
      fontWeight="normal"
      mb={isInvalid ? 0 : undefined}
      d="inline-flex"
      alignItems="baseline"
      cursor={props.disabled ? 'not-allowed' : undefined}
      pointerEvents={props.readOnly ? 'none' : null}
      onClick={e => {
        e.preventDefault();
        if (!props.disabled && !props.readOnly) {
          props.onChange({
            target: { name: props.name || props.id, value: props.value, checked: !props.isChecked },
          });
          if (props.postOnChange) props.postOnChange(props.value);
        }
      }}
    >
      {/* This is the sibling input, it's visually hidden */}
      <VisuallyHidden
        as="input"
        type="radio"
        aria-invalid={isInvalid}
        checked={isChecked}
        name={props.name || props.id}
        id={props.id || props.name}
        {...props}
      />

      <ControlBox
        as="svg"
        viewBox="0 0 16 16"
        fill="none"
        size={4}
        mr={3}
        type="radio"
        position="relative"
        top="2px"
        _child={{
          opacity: 1,
          transition: 'all 0.3s',
          '&:not(:first-of-type)': {
            opacity: 0,
            strokeWidth: 0,
          },
        }}
        _checkedAndChild={{
          opacity: 1,
          strokeWidth: 3,
          '&:first-of-type': {
            opacity: 0,
          },
        }}
        _checked={{
          '~ span': {
            fontWeight: 600,
            color: 'inherit',
          },
        }}
        _checkedAndDisabled={{
          circle: {
            '&:nth-of-type(2)': {
              stroke: 'semiDarkGray.900',
            },
            '&:last-of-type': {
              fill: 'semiDarkGray.600',
            },
          },
        }}
        _focus={{
          boxShadow: 'outlineThin',
          rounded: 'full',
        }}
        _disabled={{
          '~ span, &': {
            opacity: '0.6',
          },
        }}
        _invalid={{
          'circle:first-of-type': {
            stroke: 'errorBorderColor',
          },
        }}
      >
        <circle cx="8" cy="8" r="5.5" stroke={theme.colors.gray} />
        <circle cx="8" cy="8" r="6" stroke={theme.colors.red} strokeWidth="3" />
        <circle
          r="2"
          transform="matrix(-1 0 0 1 8 8)"
          fill={theme.colors.coral[900]}
          fillOpacity="0.6"
        />
      </ControlBox>

      <Box as="span" userSelect="none" color="semiDarkGray.600">
        {children}
      </Box>
    </Label>
  );
};

export const SearchInput = ({ readOnly, ...props }) => {
  const [field] = useField(props);

  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  return (
    <BaseInput
      placeholder="Recherche"
      type="search"
      border={0}
      bg="search.bg.light"
      minW="400px"
      _placeholder={{ color: 'search.text.light', fontWeight: 'normal' }}
      {...field}
      value={field.value || field.value === 0 ? field.value : ''}
      readOnly={isFormSubmitDisabled || readOnly}
      {...props}
    />
  );
};

export const CheckboxGroup = ({ children, legend, requiredInput, readOnly, ...props }) => {
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  const [, meta, helpers] = useField(props.name);
  const isInvalid = meta.error && !meta.touched;
  const childrenWithInvalid = React.Children.map(children, child =>
    React.cloneElement(child, {
      ...child.props,
      readOnly: child.props.readOnly || readOnly || isFormSubmitDisabled,
      disabled: child.props.disabled || props.disabled,
      name: props.name,
    }),
  );

  return (
    <Box as="fieldset" mb={4}>
      {legend && (
        <Label as="legend" {...(props.isInline ? {} : { mb: 3 })}>
          {legend}
          {requiredInput && ' *'}
        </Label>
      )}
      <CGroup
        onChange={helpers.setValue}
        isInvalid={isInvalid}
        css={css`
          & > div {
            min-width: 37%;
          }
        `}
        value={meta.value || []}
        {...props}
        id={props.id || props.name}
      >
        {childrenWithInvalid}
      </CGroup>
      {!meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Box>
  );
};

export const CheckboxGroupInput = ({
  isInvalid,
  field,
  meta,
  helpers,
  mr,
  mb,
  children,
  ...props
}) => {
  const theme = useTheme();

  // console.log(meta, props.isChecked);

  return (
    <Box minW="37%" mr={mr} mb={mb}>
      <Label
        color="semiDarkGray.900"
        fontWeight="normal"
        mb={isInvalid ? 0 : undefined}
        d="inline-flex"
        alignItems="baseline"
        cursor={props.disabled ? 'not-allowed' : undefined}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          if (!props.disabled && !props.readOnly) {
            if (helpers) {
              helpers.setValue(!meta.value);
            } else {
              props.onChange({ target: { value: props.value, checked: !props.isChecked } });
            }
          }
        }}
      >
        {/* This is the sibling input, it's visually hidden */}
        <VisuallyHidden
          as="input"
          type="checkbox"
          checked={props.isChecked}
          {...field}
          aria-invalid={isInvalid}
          {...props}
          name={props.name || props.id}
          id={props.id || props.name}
        />

        {/* This is the control box with a check icon as children */}
        <ControlBox
          as="svg"
          size={5}
          rounded="md"
          position="relative"
          top="3px"
          mr={3}
          viewBox="0 0 20 20"
          fill="none"
          _child={{
            opacity: 1,
            transition: 'all 0.3s',
            '&:not(:first-of-type)': {
              opacity: 0,
              strokeWidth: 0,
            },
          }}
          _checkedAndChild={{
            opacity: 1,
            strokeWidth: 3,
            '&:first-of-type': {
              opacity: 0,
            },
          }}
          _checked={{
            '~ span': {
              fontWeight: 600,
              color: 'inherit',
            },
          }}
          _checkedAndDisabled={{
            rect: {
              '&:nth-of-type(2)': {
                stroke: 'semiDarkGray.900',
              },
              '&:last-of-type': {
                fill: 'semiDarkGray.600',
              },
            },
          }}
          _focus={{
            boxShadow: 'outlineThin',
          }}
          _disabled={{
            '~ span, &': {
              opacity: '0.6',
            },
          }}
          _invalid={{
            'rect:first-of-type': {
              stroke: 'errorBorderColor',
            },
          }}
        >
          <rect x="2.5" y="2.5" width="15" height="15" rx="2.5" stroke={theme.colors.gray} />
          <rect
            x="2"
            y="2"
            width="16"
            height="16"
            rx="3"
            stroke={theme.colors.red}
            strokeWidth="3"
          />
          <rect
            width="8.88889"
            height="8.88889"
            rx="2"
            transform="matrix(-1 0 0 1 14.4443 5.55566)"
            fill={theme.colors.coral[900]}
            fillOpacity="0.6"
          />
        </ControlBox>
        <Box as="span" userSelect="none" color="semiDarkGray.600">
          {children}
        </Box>
      </Label>
    </Box>
  );
};

export const CheckBoxInput = ({ readOnly, ...props }) => {
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  const [field, meta, helpers] = useField({ ...props, type: 'checkbox' });
  const isInvalid = meta.error && !meta.touched;

  return (
    <CheckboxGroupInput
      isInvalid={isInvalid}
      field={field}
      meta={meta}
      helpers={helpers}
      readOnly={readOnly || isFormSubmitDisabled}
      {...props}
    />
  );
};

export const SwitchInput = ({
  children,
  mb,
  yesLabel,
  noLabel,
  isIndeterminate,
  readOnly,
  ...props
}) => {
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  const [field, meta, helpers] = useField({ ...props, type: 'checkbox' });
  const isInvalid = meta.error && !meta.touched;

  const checkRef = useRef();

  useEffect(() => {
    if (isIndeterminate) {
      checkRef.current.indeterminate = !meta.value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box as="fieldset" mb={mb}>
      <Box as="legend" color="semiDarkGray.600" mb={3} fontWeight={600}>
        {props.label}
      </Box>
      <Label
        color="semiDarkGray.900"
        mb={isInvalid ? 0 : undefined}
        d="inline-flex"
        alignItems="center"
        pointerEvents={props.readOnly || isFormSubmitDisabled ? 'none' : null}
        cursor={props.disabled ? 'not-allowed' : null}
        onClick={e => {
          e.preventDefault();
          if (!props.disabled && !props.readOnly) {
            if (helpers) {
              helpers.setValue(!meta.value);
            } else {
              props.onChange({ target: { value: props.value, checked: !props.isChecked } });
            }
          }
        }}
      >
        <Switch
          ref={checkRef}
          css={theme => ({
            height: '1rem',
            margin: '0 1ch',
            '> div': {
              backgroundColor: lightenDarkenColor(theme.colors.gray2, 15),
              width: '2.19rem',
              height: '1rem',
              padding: 0,
              div: {
                width: '1.125rem',
                height: '1.125rem',
                backgroundColor: theme.colors.gray2,
              },
            },
            'input[type=checkbox]:checked + div': {
              backgroundColor: theme.colors.coral[400],
              div: {
                backgroundColor: theme.colors.coral[900],
                transform: 'translateX(calc(2.19rem - 1.125rem))',
              },
            },
            'input[type=checkbox]:indeterminate + div': {
              div: {
                transform: 'translateX(calc((2.19rem - 1.125rem) / 2))',
              },
            },
            'input[type=checkbox]:focus + div': { boxShadow: 'none' },
          })}
          {...field}
          isChecked={field.checked}
          name={props.name || props.id}
          id={props.id || props.name}
          isDisabled={props.disabled}
          {...props}
        />
        <Text
          as="span"
          opacity={field.checked || checkRef.current?.indeterminate ? '0.6' : '1'}
          fontWeight={field.checked || checkRef.current?.indeterminate ? 300 : 600}
          transition=".3s ease-in-out"
          order="-1"
        >
          {noLabel || 'En attente'}
        </Text>
        <Text
          as="span"
          opacity={field.checked ? '1' : '0.6'}
          fontWeight={field.checked ? 600 : 300}
          transition=".3s ease-in-out"
        >
          {yesLabel || 'Reçu'}
        </Text>
      </Label>
    </Box>
  );
};

export const ErrorMessage = ({ children, ...props }) => {
  return (
    <Text fontSize="14px" pl={3} mt={1} color="errorBorderColor" {...props}>
      {children?.map((error, index) => (
        <span key={error}>
          {error} {index < children.length && <br />}
        </span>
      ))}
    </Text>
  );
};

export const Label = ({ children, htmlFor, ...props }) => {
  return (
    <FormLabel
      as="label"
      mb={1}
      d="block"
      color="semiDarkGray.600"
      fontWeight="600"
      htmlFor={htmlFor}
      {...props}
    >
      {children}
    </FormLabel>
  );
};

export const BaseInput = React.forwardRef(({ style, ...props }, ref) => {
  const { isInvalid, isLocked, disabled } = props;
  return (
    <Box position="relative" {...style}>
      {isInvalid && <IconError />}
      <Input
        size="sm"
        borderColor="lightGray2"
        color="semiDarkGray.900"
        fontWeight="600"
        fontSize="16px"
        rounded="lg"
        ref={ref}
        _placeholder={{ color: 'placeholder', fontWeight: 'normal' }}
        _focus={{ borderColor: isLocked ? 'input.borderColor.locked' : 'coral.400' }}
        _invalid={{ borderColor: 'errorBorderColor' }}
        _disabled={{
          cursor: 'not-allowed',
          backgroundColor: `input.bg.${isLocked ? 'locked' : 'disabled'}`,
          borderColor: `input.borderColor.${isLocked ? 'locked' : 'disabled'}`,
          color: `input.color.${isLocked ? 'locked' : 'disabled'}`,
          opacity: isLocked ? '1' : '0.3',
        }}
        _readOnly={{
          backgroundColor: disabled
            ? `input.bg.${isLocked ? 'locked' : 'disabled'}`
            : 'transparent',
          borderColor: disabled ? `input.borderColor.${isLocked ? 'locked' : 'disabled'}` : null,
          userSelect: 'all',
          boxShadow: 'none !important',
        }}
        name={props.name || props.id}
        id={props.id || props.name}
        {...props}
      />
    </Box>
  );
});

export const IconError = props => (
  <Icon
    name="error"
    position="absolute"
    color="red"
    zIndex="1"
    size={6}
    top="50%"
    left="0"
    transform="translate(-70%,-50%)"
    {...props}
  />
);
