import React, { useState } from 'react';
import {
  Divider,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/core';
import { apiV1 } from '../../api/api';
import { Section } from '../Sections';
import { TabTableWrap, TabTBodyRow, TabTBodyTH, TabTD } from '../Tabs';
import { Table } from '../Table';

export const Funders = ({ folderId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [folder, setFolder] = useState({});

  const folderFormattedId = folder?.folderFormattedId || folder?.formattedId;
  const recipientFullName = folder?.recipientLastName ? (
    <Text mt={2} as="small">
      <Text as="span" fontWeight="semibold" textTransform="uppercase">
        {folder?.recipientLastName || '-'}
      </Text>{' '}
      {folder?.recipientFirstName || '-'}
    </Text>
  ) : (
    <Text mt={2} as="small">
      {folder?.recipientFullName || '-'}
    </Text>
  );

  const recipientHousingCity = folder?.recipientCity || folder?.recipientHousingCity;
  const folderTag = folder?.tag || folder?.folderTag;

  const openFundersModal = () => {
    apiV1.get(`/folders/${folderId}/folder_details`).then(({ data }) => {
      setFolder(data.folder);
      onOpen();
    });
  };
  return (
    <>
      <Tooltip
        bg="semiDarkGray.900"
        label="Détails dossier"
        placement="top"
        zIndex={1000}
        aria-label="Détails dossier"
      >
        <IconButton
          icon="info-outline"
          variant="ghost"
          variantColor={null}
          onClick={openFundersModal}
          color="coral.900"
          height={8}
          minW={8}
          rounded="full"
          _hover={{ color: 'white', backgroundColor: 'coral.900' }}
          _focus={{ shadow: 'none' }}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent rounded="lg" maxWidth="60rem">
          <ModalHeader as="div" bg="darkGray" color="white" fontSize="md" roundedTop="lg">
            <Heading as="h2" fontSize="xl" fontWeight="bold">
              <Text
                as="span"
                fontFamily="heading"
                opacity="0.5"
                textTransform="uppercase"
                fontWeight="600"
              >
                Dossier n°{' '}
              </Text>
              {folderFormattedId}{' '}
              <Text as="span" d="inline-block" mx={2} opacity="0.65">
                -
              </Text>{' '}
              {recipientFullName}
            </Heading>
            <Text mt={2}>
              <Text as="span" fontWeight="bold" opacity="0.5">
                Contact:{' '}
              </Text>
              <Text as="span">
                {folder.contactDisplayName} - {folder.contactPhoneNumber} - {folder.contactEmail}
              </Text>
            </Text>
            <Text mt={2}>
              <Text as="span" fontWeight="bold" opacity="0.5">
                Logement à adapter:{' '}
              </Text>
              <Text as="span">{folder.housingFullAddress || '-'}</Text>
            </Text>
            <Text mt={2}>
              <Text as="span">{recipientHousingCity || '-'}</Text>
              <Text as="span" d="inline-block" opacity="0.65">
                &nbsp;/&nbsp;
              </Text>
              <Text as="span" textTransform="uppercase">
                {folderTag || '-'}
              </Text>
            </Text>
          </ModalHeader>
          <ModalCloseButton color="white" _focus={{ shadow: 'none' }} />
          <ModalBody pb={5}>
            <Section mt={5}>
              <TabTableWrap textAlign="left" px="0.5rem">
                <Text px={3} py={1}>
                  Ergothérapeute:{' '}
                  {folder.ergoUser ? folder.ergoUser.fullName : "En attente d'affectation"}
                </Text>
                {folder.ergoUser ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      Coordonnées: {folder.ergoUser.phoneNumber} - {folder.ergoUser.email}
                    </Text>
                  </>
                ) : null}
                {folder.recipientContactedAt ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      Dernière date de contact pour planification de la visite à domicile:{' '}
                      {folder.recipientContactedAt}
                    </Text>
                  </>
                ) : null}
                {folder.housingVisitedAt ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      Date de visite à domicile: {folder.housingVisitedAt}
                    </Text>
                  </>
                ) : null}
                {folder.primary_2dpeFinalizedAt ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      Date de la dernière version du compte-rendu: {folder.primary_2dpeFinalizedAt}
                    </Text>
                  </>
                ) : null}
                {folder.analysisFinishedAt ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      Date de transmission pour validation du projet au bénéficiaire:{' '}
                      {folder.analysisFinishedAt}
                    </Text>
                  </>
                ) : null}
                {folder.pendingAt ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      Date de mise en instance: {folder.pendingAt}
                    </Text>
                  </>
                ) : null}
              </TabTableWrap>
            </Section>
            <Section mt={5}>
              <TabTableWrap textAlign="left" px="0.5rem">
                {folder.fundersResponse ? (
                  <Text px={3} py={1}>
                    {folder.fundersResponse}
                  </Text>
                ) : null}
                {folder.worksTotalPrice ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      {folder.worksTotalPrice}
                    </Text>
                  </>
                ) : null}
                {folder.projectPriceWithoutWorks ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      {folder.projectPriceWithoutWorks}
                    </Text>
                  </>
                ) : null}
                {folder.totalToPayByRecipient ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} pt={1} pb={3}>
                      {folder.totalToPayByRecipient}
                    </Text>
                  </>
                ) : null}
              </TabTableWrap>
            </Section>
            <Section mt={5}>
              <TabTableWrap px="0.5rem">
                {Object.keys(folder.eligibleFundersMonitoring || {}).length ? (
                  <Table>
                    <thead>
                      <TabTBodyRow>
                        <TabTBodyTH w="300px" borderRight="0" textAlign="left" fontWeight="bold">
                          Financeur
                        </TabTBodyTH>
                        <TabTBodyTH w="300px" borderRight="0" textAlign="left" fontWeight="bold">
                          Montant accordé
                        </TabTBodyTH>
                        <TabTBodyTH w="300px" borderRight="0" textAlign="left" fontWeight="bold">
                          Date de demande
                        </TabTBodyTH>
                        <TabTBodyTH w="300px" borderRight="0" textAlign="left" fontWeight="bold">
                          Date de retour
                        </TabTBodyTH>
                      </TabTBodyRow>
                    </thead>
                    <tbody>
                      {Object.values(folder.eligibleFundersMonitoring).map(funder => (
                        <TabTBodyRow key={funder.label}>
                          <TabTBodyTH
                            w="300px"
                            borderRight="0"
                            textAlign="left"
                            fontWeight="normal"
                          >
                            {funder.label}
                          </TabTBodyTH>
                          <TabTD color="inherit" textAlign="left" fontWeight="normal">
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {funder.isGrantedAmountKnown && typeof funder.grantedAmount === 'number'
                              ? `${funder.grantedAmount}€`
                              : funder.fundingResponse === 'not_requested'
                              ? 'Non-sollicité après accord du bénéficiaire'
                              : 'Montant non connu'}
                          </TabTD>
                          {funder.fundingRequestedAt ? (
                            <TabTD color="inherit" textAlign="left" fontWeight="normal">
                              {funder.fundingRequestedAt}
                            </TabTD>
                          ) : null}
                          {funder.funderResponseReceivedAt ? (
                            <TabTD color="inherit" textAlign="left" fontWeight="normal">
                              {funder.funderResponseReceivedAt}
                            </TabTD>
                          ) : null}
                        </TabTBodyRow>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Text textAlign="left" pb={2} pl={4}>
                    Aucun financeur disponible
                  </Text>
                )}
              </TabTableWrap>
            </Section>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
