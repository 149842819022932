import React from 'react';
import { Box } from '@chakra-ui/core';

export const PageIndex = ({ pageAction, children }) => {
  return (
    <Box px={10} pt={5} pb={5} flexGrow={1} d="flex" flexDirection="column">
      {pageAction && (
        <Box d="flex" justifyContent="space-between" alignItems="start" flexWrap="wrap" mb={2}>
          {pageAction}
        </Box>
      )}
      <Box
        bg="white"
        rounded="lg"
        shadow="layout"
        px={8}
        paddingBottom={2}
        flexGrow="1"
        d="flex"
        flexDirection="column"
      >
        {children}
      </Box>
    </Box>
  );
};
