import { expectedQuotesConstants } from './constants';
import { expectedQuotesAPI } from '../../common/api/expectedQuotes';
import { errorsActions } from '../errors/actions';

function getQuotes(page, search) {
  function request() {
    return { type: expectedQuotesConstants.GET_EXPECTED_QUOTES_REQUEST };
  }
  function success(data) {
    return { type: expectedQuotesConstants.GET_EXPECTED_QUOTES_SUCCESS, data };
  }
  function failure(error) {
    return { type: expectedQuotesConstants.GET_EXPECTED_QUOTES_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return expectedQuotesAPI.getQuotes(page, search).then(({ expectedQuotes: quotes, meta }) => {
      dispatch(success({ quotes, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateQuotesComments(quote, folder) {
  return { type: expectedQuotesConstants.UPDATE_EXPECTED_QUOTES_COMMENTS, quote, folder };
}

export const expectedQuotesActions = {
  getQuotes,
  updateQuotesComments,
};
