import React from 'react';
import { Route, Switch } from 'react-router';
import QuotesTabs from './QuotesTabs';
import routes from '../common/routes';

export const Quotes = () => {
  return (
    <Switch>
      <Route path={`${routes.quotes.index}/:quotesType`} component={QuotesTabs} />
      <Route path={routes.quotes.index} component={QuotesTabs} />
    </Switch>
  );
};
