import { apiV1 } from './api';
import { encodeQueryArray } from '../utils';

const getQuotes = (page = 1, search) =>
  apiV1
    .get(
      `/expected_quotes?page=${page}&by_keywords=${search.keywords || ''}&by_delay=${search.delay ||
        ''}${encodeQueryArray('by_tags', search.tags)}`,
    )
    .then(({ data }) => data);

export const expectedQuotesAPI = {
  getQuotes,
};
